/*
Template Name: Minton - Admin & Dashboard Template
Author: CoderThemes
Version: 6.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Custom Bootstrap Css File (Modern Demo)
*/

//Core files
@import "/srv/zookhq-repos/hq-cdn-v3.0/node_modules/bootstrap/scss/functions";
@import "/srv/zookhq-repos/hq-cdn-v3.0/node_modules/bootstrap/scss/variables";
@import "variables";
@import "/srv/zookhq-repos/hq-cdn-v3.0/node_modules/bootstrap/scss/bootstrap";

//Components
@import "../custom/components/accordions";
@import "../custom/components/badge";
@import "../custom/components/backgrounds";
@import "../custom/components/breadcrumb";
@import "../custom/components/buttons";
@import "../custom/components/card";
@import "../custom/components/dropdown";
@import "../custom/components/forms";
@import "../custom/components/modal";
@import "../custom/components/nav";
@import "../custom/components/pagination";
@import "../custom/components/popover";
@import "../custom/components/progress";
@import "../custom/components/reboot";
@import "../custom/components/tables";
@import "../custom/components/type";